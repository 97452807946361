export enum assetsTypes {
  v360 = "v360",
  video = "video",
  image = "image",
}

export interface MediaCrop {
  top: number;
  left: number;
  width: number;
  height: number;
}

export interface MediaFile {
  id: number;
  url: string;
  url_original: string;
  device: number;
  media_type: string;
  original_name: string;
  alt?: string;
}

export interface Media {
  alt: string;
  position: string;
  crop: MediaCrop;
  file: MediaFile;
  sort_order: number;
}
